import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { GetSideBarData } from '../SidebarData';

const SideBarNav = ({ user }) => {
    const data = GetSideBarData(user.isAdmin) || [];
    if (!user)
        return null;
    return (
        <>
            {
                data.map(item => <Dropdown
                    inline
                    item
                    pointing
                    fluid
                    key={item.text}
                    text={item.text}
                    icon={item.icon}
                    style={{ display: 'flex', justifyContent: 'space-evenly' }}
                    options={item.subs.map(sub => ({ ...sub, permission: 1, active: false }))}
                />)
            }
        </>

    );
};

export default SideBarNav;