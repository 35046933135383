import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RequireAuth, RequireNoAuth } from '../middlewares/HOC';
import getPaths from './PATHS';

import {
    LandginHome,
    Signin, ResetPassword, Activation,
    AdminDashboard,

    AdminCustomers,

    AdminFinPayments, AdminFinWithDraws, AdminFinCash,
    AdminFinBills, AdminFinNitros,
    AdminFinCustomerBalance
} from '../pages';

const Router = () => {
    const PATHS = getPaths();
    return <Switch>
        {/* Basic Routes */}
        <Route path={PATHS.HOME.URL} exact component={LandginHome} />
        <Route path={PATHS.RESET_PASSWORD.URL} exact component={ResetPassword} />
        <Route path={PATHS.ACTIVATION.URL} exact component={Activation} />

        {/* <Route path="/signup" exact component={RequireNoAuth(Signup)} /> */}
        <Route path={PATHS.SIGNIN.URL} exact component={RequireNoAuth(Signin)} />
        {/* <Route path="/profile" exact component={Profile} /> */}



        <Route path={PATHS.ADMIN_DASHBOARD.URL} exact component={RequireAuth(AdminDashboard)} />

        <Route path={PATHS.ADMIN_CUSTOMERS.URL} exact component={RequireAuth(AdminCustomers)} />

        <Route path={PATHS.ADMIN_FIN_BILLS.URL} exact component={RequireAuth(AdminFinBills)} />
        <Route path={PATHS.ADMIN_FIN_PAYMENTS.URL} exact component={RequireAuth(AdminFinPayments)} />
        <Route path={PATHS.ADMIN_FIN_WITHDRAWS.URL} exact component={RequireAuth(AdminFinWithDraws)} />
        <Route path={PATHS.ADMIN_FIN_CASH.URL} exact component={RequireAuth(AdminFinCash)} />
        <Route path={PATHS.ADMIN_FIN_NITROS.URL} exact component={RequireAuth(AdminFinNitros)} />

        <Route path={PATHS.ADMIN_FIN_REPORTS_CUSTOMER_BALANCE.URL} exact component={RequireAuth(AdminFinCustomerBalance)} />


        {/* {renderRoutes(user.roleId)} */}

        {/* Invalid Routes */}
        <Route>
            <Route path="*" exact component={LandginHome} />
            <Redirect from='*' to='/' />
        </Route>

    </Switch>;
};

export default Router;
