
export default () => ({
    HOME: { URL: '/', RESOURCES: [], PERMISSIONS: [] },
    RESET_PASSWORD: { URL: '/resetpassword/:resetCode', RESOURCES: [], PERMISSIONS: [] },
    ACTIVATION: { URL: '/activation', RESOURCES: [], PERMISSIONS: [] },
    SIGNIN: { URL: '/signin', RESOURCES: [], PERMISSIONS: [] },
    ADMIN_DASHBOARD: { URL: '/admin/dashboard', RESOURCES: ['ADMINS_CONTROL_PANEL'], PERMISSIONS: [] },
    ADMIN_CUSTOMERS: { URL: '/admin/financial/customers', RESOURCES: [`AW_CUSTOMERS`], PERMISSIONS: ['READ', 'ADD'] },
    ADMIN_FIN_BILLS: { URL: '/admin/fin/bills', RESOURCES: [`AW_FINANCIAL_BILLS`], PERMISSIONS: [] },
    ADMIN_FIN_PAYMENTS: { URL: '/admin/fin/payments', RESOURCES: [`AW_FINANCIAL_PAYMENTS`], PERMISSIONS: [] },
    ADMIN_FIN_WITHDRAWS: { URL: '/admin/fin/withdraws', RESOURCES: [`AW_FINANCIAL_WITHDRAWS`], PERMISSIONS: [] },
    ADMIN_FIN_CASH: { URL: '/admin/fin/cash', RESOURCES: [`AW_FINANCIAL_CASH`], PERMISSIONS: [] },
    ADMIN_FIN_NITROS: { URL: '/admin/fin/nitros', RESOURCES: [`AW_FINANCIAL_NITROS`], PERMISSIONS: [] },

    ADMIN_FIN_REPORTS_CUSTOMER_BALANCE: { URL: '/admin/fin/reports/customer_balance', RESOURCES: [`AW_FINANCIAL_REPORTS`], PERMISSIONS: [] },
});