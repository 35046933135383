import i18n from 'i18next';
import {
    RESET_PASSWORD_OPERATION_FAIL,
    RESET_PASSWORD_OPERATION_FINISH,
    RESET_PASSWORD_OPERATION_START,
    RESET_PASSWORD_SUCCESS,
} from '../types';
import { badReq, noConnection } from '../../helpers/errors';
import request from '../../api';
import history from '../../history';
import getPaths from '../../Routers/PATHS';
const PATHS = getPaths();

export const resetPassword = (resetCode, newPassword) => async dispatch => {
    dispatch({ type: RESET_PASSWORD_OPERATION_START });
    try {
        await request.post('/user/profile/update', { password: newPassword });
        dispatch({ type: RESET_PASSWORD_SUCCESS });
        history.push(PATHS.HOME.URL);
    } catch (err) {
        dispatch({ type: RESET_PASSWORD_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        if (err.response) return badReq();
        else noConnection();
    } finally {
        dispatch({ type: RESET_PASSWORD_OPERATION_FINISH });
    }
};