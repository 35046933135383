import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import getPaths from '../Routers/PATHS';

export const GetSideBarData = (isAdmin) => {
    const { t } = useTranslation();
    const PATHS = getPaths();

    if (isAdmin) return [

        {
            text: t('Menu'), icon: 'list', subs: [
                { key: 1, as: Link, value: 1, text: t('Customers'), icon: 'users', to: PATHS.ADMIN_CUSTOMERS.URL },
                { key: 2, as: Link, value: 2, text: t('Bills'), icon: 'object group', to: PATHS.ADMIN_FIN_BILLS.URL },
                { key: 3, as: Link, value: 3, text: t('Payments'), icon: 'dollar', to: PATHS.ADMIN_FIN_PAYMENTS.URL },
                { key: 4, as: Link, value: 4, text: t('Withdraws'), icon: 'money', to: PATHS.ADMIN_FIN_WITHDRAWS.URL },
                { key: 5, as: Link, value: 5, text: t('CashBox'), icon: 'archive', to: PATHS.ADMIN_FIN_CASH.URL },
                { key: 6, as: Link, value: 6, text: t('Nitros'), icon: 'braille', to: PATHS.ADMIN_FIN_NITROS.URL },
                { key: 7, as: Link, value: 7, text: t('CustomerBalance'), icon: 'balance', to: PATHS.ADMIN_FIN_REPORTS_CUSTOMER_BALANCE.URL },

            ]
        }
    ];
};