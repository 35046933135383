import React, { useState, useEffect, createRef, Suspense } from 'react';
import { Router as PlainRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { Sticky } from 'semantic-ui-react';
import cookies from 'react-cookies';
import { NavBar, ScrollToTop, Spinner } from './components';
import Router from './Routers';
import history from './history';
import { checkAuth } from './actions';
import onLangChange from './helpers/checkLang';

const Main = (props) => {
    const cookiesLang = cookies.load('languagePref');
    const { user, checkAuth } = props;
    const [lang, setLang] = useState(cookiesLang || i18n.language);
    const divRef = createRef();

    useEffect(() => {
        checkAuth();
        onLangChange(lang, setLang);
        // eslint-disable-next-line
    }, []);

    if (user === null) return <Spinner />;

    return (
        <PlainRouter history={history}>
            <div ref={divRef}>
                <Sticky context={divRef}>
                    <NavBar onLangChange={onLangChange} lang={lang} setLang={setLang} />
                </Sticky>
                <Suspense fallback={<Spinner />}>
                    <Router />
                </Suspense>
            </div>
            <ScrollToTop />
            {/* <PanelButton lang={lang} toggle={onToggleSidebar} showSidebar={showSidebar} user={user} /> */}

        </PlainRouter>
    );
};

const mapStateTopProps = ({ auth }) => {
    const { user } = auth;
    return { user };
};
export default connect(mapStateTopProps, { checkAuth })(Main);