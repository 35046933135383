import i18n from 'i18next';
import {
    SIGNIN_INFO_CHANGED,
    SIGNIN_START,
    SIGNIN_SUCCESS,
    SIGNIN_FAIL,
    SIGNIN_FINISH,
    SIGNOUT,
} from '../types';
import request from '../../api';
import history from '../../history';
import { noConnection, badReq } from '../../helpers/errors';
import getPaths from '../../Routers/PATHS';
const PATHS = getPaths();

export const signinInfoChanged = ({ props, value }) => {
    return { type: SIGNIN_INFO_CHANGED, payload: { props, value } };
};

export const signin = (userName, password) => async (dispatch, getState) => {
    dispatch({ type: SIGNIN_START });

    try {
        const { data } = await request.post('/auth/login', { loginName: userName, password });
        if (data) {
            await signinSuccess(dispatch, getState, data);
        } else signinFail(dispatch, i18n.t('SigninFail'));
    } catch (err) {
        if (err.response) {
            if (err.response.data.error && err.response.data.error.code === 'InactiveAccount') history.push(PATHS.ACTIVATION.URL);
            else signinFail(dispatch, err.response.data.msg);
        }
        else noConnection();
    } finally {
        dispatch({ type: SIGNIN_FINISH });
    }
};

export const signinSuccess = async (dispatch, getState, user, isCheckAuthed) => {
    dispatch({ type: SIGNIN_SUCCESS, payload: user });
    if (!isCheckAuthed) history.push(PATHS.ADMIN_DASHBOARD.URL);
};

const signinFail = (dispatch, err) => {
    dispatch({ type: SIGNIN_FAIL, payload: err });
};

export const checkAuth = () => async (dispatch, getState) => {
    try {
        const { data } = await request.get('/auth/is_loggedin');
        signinSuccess(dispatch, getState, data.user || "", true);

    } catch (err) {
        signout()(dispatch);
        if (err.response) badReq();
        else noConnection();
    }
};

export const signout = () => async dispatch => {
    try {
        await request.get('/auth/logout');
        history.push(PATHS.HOME.URL);
        dispatch({ type: SIGNOUT });
    } catch (err) {
        if (err.response) badReq();
        else noConnection();
    }
};
