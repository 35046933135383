import axios from 'axios';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import { signout } from '../actions';
import { store } from '../store';

const { dispatch } = store;


export const BASE_URL = '/api';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true
});

axiosInstance.interceptors.request.use(
    (request) => {
        if (request.method === 'post' || request.method === 'delete') {
            const toastId = toast.loading(i18next.t("PleaseWait"));
            request['toastId'] = toastId;
        }
        return request;
    },
    undefined
);

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.config.method === 'post' || response.config.method === 'delete') {
            toast.update(response.config.toastId, { render: i18next.t('Success'), type: "success", isLoading: false, autoClose: 2000, closeButton: true });
        }
        return response;
    },
    (err) => {
        if (err.response.status === 401 && err.response.config.url !== '/auth/login') {
            return signout()(dispatch);
        }
        if (err.response?.data?.code) {
            toast.update(err.config.toastId, { render: i18next.t(err.response.data.code), type: "error", isLoading: false, autoClose: 5000, closeButton: true });
        } else toast.update(err.config.toastId, { render: i18next.t("ErrorBody"), type: "error", isLoading: false, autoClose: 5000, closeButton: true });

        return Promise.reject(err);
    }
);

export default axiosInstance;