import { combineReducers } from 'redux';

import SigninReducer from './landing section/SigninReducer';
import ForgotPasswordReducer from './landing section/ForgotPasswordReducer';
import ResetPasswordReducer from './landing section/ResetPasswordReducer';
import ActicationCodeReducer from './landing section/ActivationReducer';
import CountriesReducer from './landing section/CountriesReducer';


export default combineReducers({
    auth: SigninReducer,
    forgot: ForgotPasswordReducer,
    resetPassword: ResetPasswordReducer,
    activation: ActicationCodeReducer,
    countries: CountriesReducer,


});