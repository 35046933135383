import { lazy } from 'react';

// LANDING SECTION
const LandginHome = lazy(() => import('./landing section/Home'));
const Signin = lazy(() => import('./landing section/Auths/Signin'));
const ResetPassword = lazy(() => import('./landing section/Auths/ResetPassword'));
const Activation = lazy(() => import('./landing section/Auths/Activation'));

// ADMIN SECTION
const AdminDashboard = lazy(() => import('./admin section/Dashboard'));
// ADMIN FINANCIAL SECTION 
const AdminCustomers = lazy(() => import('./admin section/Financial/Customers/Customers'));
const AdminFinBills = lazy(() => import('./admin section/Financial/Bills/Bills'));
const AdminFinPayments = lazy(() => import('./admin section/Financial/Payments'));
const AdminFinWithDraws = lazy(() => import('./admin section/Financial/withdraws'));
const AdminFinCash = lazy(() => import('./admin section/Financial/Cash'));
const AdminFinNitros = lazy(() => import('./admin section/Financial/Nitro'));
const AdminFinCustomerBalance = lazy(() => import('./admin section/Financial/Reports/CustomerBalance'));



export {
    LandginHome,
    Signin, ResetPassword, Activation,
    AdminDashboard,

    AdminCustomers,

    AdminFinPayments, AdminFinWithDraws, AdminFinCash,
    AdminFinBills, AdminFinNitros,
    AdminFinCustomerBalance

};